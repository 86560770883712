<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center justify-content-end flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <i18n textColor="text-black" class="pr-2" :isShow="!verticalNavMenuItemsMin"/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>

          <!-- <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{$store.state.session.AppActiveUser().displayName}}
            </p>
            <span class="user-status">{{$store.state.session.AppActiveUser().userRole === 'A' ? 'Administrador' : 'Lector'}}</span>
          </div> -->
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar || null"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" to="/profile">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { empty } from '@/libs/tools/helpers'
import request from '@/libs/request/index'
import store from '@/store/index'
import i18n from '@core/spore-components/i18n/i18n'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    i18n
    // Navbar Components
    // DarkToggler
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      avatar: ''
    }
  },
  methods: {
    logout () {
      localStorage.setItem('avatar', '')
      this.$cookies.remove('session')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/')
    },
    async getProfile () {
      const params = {
        url: '/user/avatar',
        method: 'GET'
      }
      await request(params).then(data => {
        if (!empty(data.data.data.avatar)) {
          this.avatar = 'data:image/jpeg;base64,' + data.data.data.avatar
          localStorage.setItem('avatar', 'data:image/jpeg;base64,' + data.data.data.avatar)
        }
      })
      console.log(this.avatar)
    }
  },
  created () {
    if (!empty(store.state.session.AppActiveUser())) {
      if (empty(localStorage.getItem('avatar'))) {
        this.getProfile()
      } else {
        this.avatar = localStorage.getItem('avatar')
      }
    }
  }
}
</script>
