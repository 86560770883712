const menu = () => {
  return [
    {
      title: 'Dashboard',
      route: 'tracker-dashboard',
      icon: 'ActivityIcon',
      permission: ['manage']
    },
    {
      title: 'ServiceButton',
      route: 'panic_button',
      icon: 'TargetIcon',
      permission: ['TB', 'B']
    },
    {
      title: 'Dashboard',
      route: 'panic_button-dashboard',
      icon: 'ActivityIcon',
      permission: ['TB', 'B']
    },
    {
      title: 'MapServiceButton',
      route: 'panic_button_map',
      icon: 'MapIcon',
      permission: ['TB', 'B']
    },
    {
      title: 'Trackers',
      route: 'tracker',
      icon: 'MapPinIcon',
      permission: ['TB', 'T']
    },
    {
      title: 'Requests',
      route: 'requests',
      icon: 'BellIcon',
      permission: ['TB', 'T']
    },
    {
      title: 'SharedLinks',
      route: 'shared-links',
      icon: 'ExternalLinkIcon',
      permission: ['TB', 'T']
    },
    {
      title: 'Tracings',
      route: 'tracings',
      icon: 'MapIcon',
      permission: ['TB', 'T']
    },
    {
      title: 'Users',
      route: 'users',
      icon: 'UserIcon',
      permission: ['TB', 'T']
    }
  ]
}

export default menu()
