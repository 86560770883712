<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow bg-primarySpore"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div :class="isMouseHovered || !isVerticalMenuCollapsed ? 'navbar-header expanded mb-5' : 'navbar-header expanded mb-3'">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed" :collapseTogglerIcon="collapseTogglerIcon" >
        <b-link class="d-inline-block" to="/">
          <span :class="isMouseHovered || !isVerticalMenuCollapsed ? 'd-flex align-items-center pl-2 mt-1  d-inline-block' : 'd-flex align-items-center mt-1 d-inline-block'">
            <b-img
              :src="isMouseHovered || !isVerticalMenuCollapsed ? appLogoImage : appLogoCircle"
              alt="logo"
              :style="isMouseHovered || !isVerticalMenuCollapsed ? 'width: 100%' : 'width: 23%; margin-left: -12px;'"
            />
            <b-link v-if="isMouseHovered || !isVerticalMenuCollapsed" class="nav-link modern-nav-toggle d-inline-block mb-5 pb-5" style="margin-left: -15px;">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none text-white"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon text-white"
                @click="toggleCollapsed"
              />
            </b-link>
          </span>
        </b-link>
      </slot>
    </div>
    <div class="d-flex pt-1 pb-1 text-white ml-1 mr-1" style="border-radius: 4px; background-color: #433189; cursor:pointer; z-index:10000; margin-top: 6rem;">
      <div class="text-center ml-1">
        <b-avatar href="/profile" :size="(isVerticalMenuCollapsed ? '25px' : '40px')" class="shadow" :src="avatar || null"/>
      </div>
      <div v-if="isMouseHovered || !isVerticalMenuCollapsed" class="text-center ml-1" :style="(isVerticalMenuCollapsed ? 'margin-top: 5px;' : 'margin-top: 10px;')">
        <b-link href="/profile" class="text-white font-weight-bold">{{$store.state.session.AppActiveUser().displayName.substring(0,15)}}</b-link>
      </div>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="menuData"
        v-if="menuData"
        class="navigation navigation-main bg-primarySpore text-white"
      />
      <div v-if="isVerticalMenuCollapsed" class="pt-1 pb-1 text-white ml-1 mr-1" style="border-radius: 4px; background-color: #433189; cursor:pointer; z-index:10000; margin-top: 10rem;" @click="logout()">
        <feather-icon class="ml-2" icon="LogOutIcon" svgClasses="stroke-current w-10 h-10" />
        <span v-if="isMouseHovered || !isVerticalMenuCollapsed" class="menu-title text-truncate text-white ml-1">{{$t('Logout')}}</span>
      </div>
      <div v-if="!isVerticalMenuCollapsed" class="px-5 pt-1 pb-1 text-white ml-1 mr-1" style="border-radius: 4px; background-color: #433189; cursor:pointer; z-index:10000; margin-top: 10rem;" @click="logout()">
        <feather-icon icon="LogOutIcon" svgClasses="stroke-current w-10 h-10" />
        <span class="menu-title text-truncate text-white ml-1">{{$t('Logout')}}</span>
      </div>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BAvatar } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { empty } from '@/libs/tools/helpers'
import request from '@/libs/request/index'
import store from '@/store'
// import { setSessionStorage, getSessionStorage } from '@/libs/session/index'
export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BAvatar
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, appLogoCircle } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoCircle,
      avatar: ''
    }
  },
  data () {
    return {
      menuData: []
    }
  },
  methods: {
    async getMenu () {
      const params = {
        url: '/tracker_panic_button',
        method: 'GET'
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          navMenuItems.map(item => {
            if (item.permission.indexOf(data.data.data.assign) >= 0) {
              this.menuData.push(item)
              return true
            } else {
              return false
            }
          })
        }
      })
    },
    logout () {
      localStorage.setItem('avatar', '')
      this.$cookies.remove('session')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/')
    },
    async getProfile () {
      const params = {
        url: 'user/avatar',
        method: 'GET'
      }
      await request(params).then(data => {
        if (!empty(data.data.data.avatar)) {
          this.avatar = 'data:image/jpeg;base64,' + data.data.data.avatar
          localStorage.setItem('avatar', 'data:image/jpeg;base64,' + data.data.data.avatar)
        }
      })
    }
  },
  created () {
    if (!empty(store.state.session.AppActiveUser()) && store.state.session.AppActiveUser().token.length > 0) {
      this.getMenu()
    }
    if (empty(localStorage.getItem('avatar'))) {
      this.getProfile()
    } else {
      this.avatar = localStorage.getItem('avatar')
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
