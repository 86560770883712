<template>
  <div class="text-center" :class="textColor" >
    <img class="img-min" v-if="!isShow" :src="i18n_locale_img" :alt="$i18n.locale" />
    <div class="container-i18n i18n-locale "  @click="show=!show" v-if="isShow">
      <img class="img-max" :src="i18n_locale_img" :alt="$i18n.locale" />
      <span class="label-i18n">{{ getCurrentLocaleData.lang }}
      </span>
      <feather-icon
        icon="ChevronDownIcon"
        class="icon-i18n"/>
    </div>
    <ul style="position: absolute;" class="lang-list" v-if="show && isShow">
      <li v-for="(lang,index) in locales" v-bind:key="index" class="lang-option" @click="updateLocale(lang)">
        <img class="img-max" :src="getImg(lang)" :alt="lang" /> &nbsp;{{getLanguage(lang).lang}}</li>
    </ul>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    textColor: String,
    isShow: Boolean
  },
  data () {
    const languages = process.env.VUE_APP_LANG_SUPPORTED || 'es,en'
    return {
      show: false,
      locales: languages.split(',')
    }
  },
  computed: {
    i18n_locale_img () {
      // Use below code to dynamically fetch image instead of if-else
      // NOTE: We used if-else because laravel throws error in 'yarn prod'.
      // If you are not using laravel + Vue, you can use below code to dynamically get image
      // return require(`@/assets/images/flags/${this.$i18n.locale}.png`)

      const locale = this.$i18n.locale
      if (locale === 'en') return require('@/assets/images/flags/en.png')
      else if (locale === 'pt') return require('@/assets/images/flags/pt.png')
      else if (locale === 'fr') return require('@/assets/images/flags/fr.png')
      else if (locale === 'de') return require('@/assets/images/flags/de.png')
      else if (locale === 'es') return require('@/assets/images/flags/es.png')
      else return require('@/assets/images/flags/es.png')
    },
    getCurrentLocaleData () {
      const locale = this.$i18n.locale
      if (locale === 'en') return { flag: 'us', lang: 'English' }
      else if (locale === 'pt') return { flag: 'br', lang: 'Portuguese' }
      else if (locale === 'fr') return { flag: 'fr', lang: 'French' }
      else if (locale === 'de') return { flag: 'de', lang: 'German' }
      else if (locale === 'es') return { flag: 'es', lang: 'Español' }
      else return { flag: 'es', lang: 'Español' }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$store.commit('lang/SET_LANG', locale)
      this.show = false
    },
    getLanguage (locale) {
      if (locale === 'en') return { flag: 'us', lang: 'English' }
      else if (locale === 'pt') return { flag: 'br', lang: 'Portuguese' }
      else if (locale === 'fr') return { flag: 'fr', lang: 'French' }
      else if (locale === 'de') return { flag: 'de', lang: 'German' }
      else if (locale === 'es') return { flag: 'es', lang: 'Español' }
    },
    getImg (locale) {
      return require(`@/assets/images/flags/${locale}.png`)
    }
  },
  created () {
    this.$i18n.locale = store.state.lang.language
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/i18n.scss";
</style>
